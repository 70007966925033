module.exports = {
  defaultTitle: 'Inventuz',
  logo: 'https://www.inventuz.com/favicon/favicon-512.png',
  author: 'Inventuz',
  url: 'https://www.inventuz.com',
  legalName: 'Inventuz',
  defaultDescription: 'La société Inventuz vous accompagne dans vos projets innovants. Vous avez un projet à développer ou financer ? Contactez nous.',
  socialLinks: {
    //twitter: 'http://www.twitter.com/smakosh',
    //github: 'https://github.com/smakosh',
    //linkedin: 'https://www.linkedin.com/in/ismail-ghallou-630149122/',
    //instagram: 'https://instagram.com/smakosh19',
    //youtube: 'https://www.youtube.com/user/smakoshthegamer',
    //google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
  },
  //googleAnalyticsID: '',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    //facebook: 'appId',
    //twitter: '@smakosh',
  },
  address: {
    city: 'Vannes',
    region: 'Bretagne',
    country: 'France',
    zipCode: '56034',
  },
  contact: {
    email: 'contact@inventuz.com',
    phone: '09 70 46 05 20',
  },
  foundingDate: '2012',
};
